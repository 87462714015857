import React from "react"
import P from "../../core/P"
import Icon from "../../core/Icon"
import GitexPopupModal from "../../core/GitexPopupModal"

export const Centered = ({ feature, size, theme }) => {
  const [current, setCurrent] = React.useState()
  //remove after gitex2022 start
  const [openGitex, setOpenGitex] = React.useState(false)
  const [openModelUrl, setOpenModelUrl] = React.useState()
  const openGitexModal = (event, url) => {
    setOpenGitex(true)
    setOpenModelUrl(url)
   event.stopPropagation()
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  //remove after gitex2022 end
  React.useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])
  const SectionA = e => (
    <>
      {e.fontello && (
        <Icon
          iconName={e.fontello}
          className="flex justify-center pb-5 m-0 "
          theme={theme}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-24`}>
          <div className="flex justify-center align-middle">
            <div className={`${current === "/gitex-technology-week/" ? "w-24" : "w-16"
              } h-auto rounded-md text-white flex justify-center`}>
              <div className="w-full h-auto">
                <img
                  src={e.icon?.mediaItemUrl}
                  loading="lazy"
                  height={e.icon?.mediaDetails?.height}
                  width={e.icon?.mediaDetails?.width}
                  alt={e.icon?.altText ? e.icon?.altText : e.title}
                  // title={e.title}
                // className={`${title ? "pb-5" : " "}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {e.title && (
        <P
          title={e.title}
          className={`font-bold text-center text-shark-400 ${e.description ? "pb-5" : " "
            }`}
          noPad
        />
      )}
      {e.description && (
        <P
          title={e.description}
          className={`text-center text-shark-400`}
          noPad
        />
      )}
    </>
  )
  const SectionB = e => (
    <>
      {e.fontello && (
        <i
          className={`${e.fontello} flex justify-center pb-5 m-0 text-blue-500 icon `}
          style={{ fontSize: "70px" }}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} ${current === "/gitex-2022/" ? "h-32" : "h-24"
          }`}>
          <div className="flex overflow-hidden justify-center align-middle">
            <div className={`${current === "/gitex-2022/" ? "w-28 " : "w-16"
              } h-auto rounded-md text-white flex justify-center`}>
              <div className="w-full h-auto ">
                <img
                  src={e.icon?.mediaItemUrl}
                  loading="lazy"
                  height={e.icon?.mediaDetails?.height}
                  width={e.icon?.mediaDetails?.width}
                  alt={e.icon?.altText ? e.icon?.altText : e.title}
                  // title={e.title}
                // className={`${title ? "pb-5" : " "}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {e.title && (
        <div className={`flex mx-auto justify-center items-center ${e.description ? "pb-2" : " "}`} >
          {/* <P
          title={e.title}
          className={`font-bold text-center ${e.description ? "pb-5" : " "}`}
          noPad
        /> */}
          <p
            className={`font-bold text-center text-lg font-sans max-w-4xl`}
            dangerouslySetInnerHTML={{ __html: e.title }}
          />
          {
            e.sicon?.mediaItemUrl &&
            <div className="w-5 h-5 ml-2 z-30 ">
              <a
                href={e?.siconlink?.url ? e.siconlink.url : "#"}
                className={`cursor-pointer`}
                target={e?.siconlink?.target}
                onClick={(e)=> e.stopPropagation()}
              >
                <img
                  src={e.sicon?.mediaItemUrl}
                  loading="lazy"
                  height={e.sicon?.mediaDetails?.height}
                  width={e.sicon?.mediaDetails?.width}
                  alt={e.sicon?.altText ? e.sicon?.altText : e.title}
                  // title={e.sicon?.altText ? e.sicon?.altText : e.title}
                // className={`${title ? "pb-5" : " "}`}
                />
              </a>
            </div>
          }
        </div>
      )}
      {e.description && (
        <P title={e.description} className={`text-center`} noPad />
      )}
      {
        e?.cta?.url.startsWith("https://calendly") && (<div className="flex justify-center rounded-full mt-10 ">
        <a
          className="inline-flex bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
        >
          Secure Your Slot Now
        </a>
      </div>)
      }
    </>
  )
  return (
    <>
      {openModelUrl && <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} url={openModelUrl}/>}  {/*remove after gitex2022 start*/}
      <div className="flex justify-center bg-blue-200 mx-auto">
        <div className="px-4 sm:px-6 w-screen lg:px-12  ">
          <div
            className={`lg:grid lg:grid-cols-${size} lg:gap-6 md:grid md:grid-cols-2 md:gap-6 `}
            data-aos="fade-up"
          >
            {feature?.map((e) => {
              const pointer = e?.cta?.url ? "cursor-pointer" : "cursor-default"
              // console.log(feature)
              return theme ? (
                <div
                  className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e?.icon
                    ? "transition border-t-4 border-white hover:shadow-xl hover:-translate-y-1"
                    : "transition duration-200 ease-in-out"
                    } `}
                  key={Math.random()}
                >
                  {e?.cta?.url ? (
                    <a
                      href={e?.cta?.url ? e.cta.url : "#"}
                      className={`${pointer}`}
                      target={`${current === "/gitex-technology-week/" ? "_blank" : ""
                        }`}
                    >
                      {SectionA(e)}
                    </a>
                  ) : (
                    <>{SectionA(e)}</>
                  )}
                </div>
              ) : (
                <div
                  className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e.icon
                    ? "transition border-t-4 hover:border-blue-500 border-white hover:shadow-xl hover:-translate-y-1 text-shark-400"
                    : "hover:bg-blue-500 hover:shadow-xl text-shark-400 hover:text-white transition duration-200 ease-in-out icon-card"
                    } `}
                  key={Math.random()}
                >
                  {e.cta?.url ? (
                    e.cta?.url.startsWith('https://calendly') ?
                      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                      <div onClick={(event) => openGitexModal(event,e.cta?.url)} className={`${pointer}`} onKeyDown={(event) => openGitexModal(event,e.cta?.url)}>
                        {SectionB(e)}
                      </div> :
                      <a
                        href={e?.cta?.url ? e.cta.url : "#"}
                        className={`${pointer}`}
                        target={`${current === "/gitex-technology-week/" ? "_blank" : ""
                          }`}
                      >
                        {SectionB(e)}
                      </a>
                  ) : (
                    <>{SectionB(e)}</>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Centered
// onMouseOver={(e) => e.target.style.backgroundColor = theme}
// onMouseOut={(e) => e.target.style.backgroundColor = "white"}
